<template>
    <section>
        <div v-show="codeFormVisible" style="position: fixed; width: 100vw; z-index: 999; height: 100vh; background: rgba(34, 34, 34, 0.133); padding-top: 35vh;" @click="clodeCode()">
            <div style="width: 250px; height: 250px; margin-left: 35vw;background:#FFF;border-radius:8px; ">
                <div id="qrCode" ref="qrCodeDiv"></div>
            </div>
        </div>
        <!--搜索条件-->
        <el-form :inline="true" @submit.native.prevent ref="search">
            <el-form-item>
                <el-input v-model="filters.name" placeholder="请输入店铺名称" @change="nameChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.number" placeholder="请输入店铺编号" @change="numberChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.manager" placeholder="请输入负责人" @change="managerChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.mobile" placeholder="请输入联系电话" @change="mobileChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-cascader placeholder="请选择分类，支持搜索"
                             style="width:230px"
                             v-model="filters.category"
                             :options="categorys"
                             filterable
                             :key="isResouceShow"
                             :props="{ checkStrictly: true, expandTrigger: 'hover' }" @change="categoryChange" clearable></el-cascader>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.type" placeholder="类型" @change="selectTypeChange" @clear="selectTypeClear" clearable>
                    <el-option v-for="item in select_type_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="filters.state" placeholder="状态" @change="selectChange" @clear="selectclear" clearable>
                    <el-option v-for="item in select_options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <v-distpicker :province="filters.province"
                              :city="filters.city"
                              :area="filters.area"
                              @selected="onSelected"
                              @province="onProvinceSelected"
                              @city="onCitySelected"
                              @area="onAreaSelected"
                              :placeholders="placeholders">
                </v-distpicker>
            </el-form-item>
            <el-form-item>
                <el-input v-model="filters.street" placeholder="请输入街道" @change="streetChange"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handSearch">查询</el-button>
            </el-form-item>
        </el-form>

        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        <!--列表-->
        <el-table :data="datalist"
                  @cell-click="handleClick"
                  highlight-current-row
                  @current-change="selectCurrentRow"
                  v-loading="listLoading"
                  @selection-change="selsChange"
                  style="width: 100%">
            <el-table-column type="selection" width="50"></el-table-column>
            <!--<el-table-column type="index" width=""></el-table-column>-->
            <el-table-column prop="id" label="ID" width=""></el-table-column>
            <el-table-column prop="number" label="编号" width=""></el-table-column>
            <el-table-column prop="storeName" label="名称" width=""></el-table-column>
            <!--类别[0店铺1服务商]-->
            <el-table-column prop="type" label="类型" width="">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.type == 0 ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.type == 0 ? '超市' : scope.row.type == 1 ? '服务商' :scope.row.type == 2 ? '团长' : '未设置' }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="manager" label="负责人" width=""></el-table-column>
            <el-table-column prop="categoryName" label="分类" width=""></el-table-column>
            <el-table-column prop="adminName" label="店长" width=""></el-table-column>
            <el-table-column prop="mobile" label="联系电话" width=""></el-table-column>
            <el-table-column prop="balance" label="余额" width=""></el-table-column>
            <el-table-column prop="address" label="地址" width=""></el-table-column>
            <!--审核-->
            <el-table-column prop="auditing" label="审核" width="">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.auditing ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.auditing ? "是" : "否" }}
                    </el-tag>
                </template>
            </el-table-column>
            <!--上架[0否1是]-->
            <el-table-column prop="online" label="上架" width="">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.online == 1 ? 'success' : 'danger'"
                            disable-transitions>
                        {{ scope.row.online == 1 ? "是" : "否" }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
            <el-table-column fixed="right"
                             label="操作"
                             width="80">
                <template slot-scope="scope">
                    <el-button @click="handleCode(scope.row)" type="text" size="small">推广码</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-pagination layout="prev, pager, next"
                           @current-change="handleCurrentChange"
                           :page-size="limit"
                           :total="total"
                           style="float: right">
            </el-pagination>
        </el-col>

        <!--新增界面-->
        <el-dialog title="新增"
                   :visible.sync="addFormVisible"
                   v-model="addFormVisible"
                   @close="formclear"
                   :close-on-click-modal="false">
            <el-form :model="addForm"
                     label-width="80px"
                     :rules="addFormRules"
                     ref="addForm">
                <el-form-item label="名称" prop="storeName">
                    <el-input v-model="addForm.storeName" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="编号" prop="number">
                    <el-input v-model="addForm.number" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="门头照" prop="logoImage">
                    <el-upload class="avatar-uploader"
                               action="/api/picture"
                               :show-file-list="false"
                               :on-success="handleLogoImageSuccess"
                               :before-upload="beforeLogoImageUpload">
                        <img v-if="logoImage" :src="logoImage" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="类别" prop="type">
                    <el-radio-group v-model="addForm.type">
                        <el-radio :label="0">超市</el-radio>
                        <el-radio :label="1">服务商</el-radio>
                        <el-radio :label="2">团长</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="服务区域" prop="selectcities" v-if="editForm.type==1">
                    <el-checkbox-group v-model="selectcities">
                        <el-checkbox-button v-for="city in cities" :label="city" :key="city">{{city}}</el-checkbox-button>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item prop="categoryIdArr" label="分类" width sortable>
                    <el-cascader placeholder="请选择，支持搜索功能"
                                 style="width: 400px"
                                 v-model="addForm.categoryIdArr"
                                 :options="categorys"
                                 filterable
                                 :key="isResouceShow"
                                 :props="{ checkStrictly: true, expandTrigger: 'hover' }"
                                 v-if="!addLoading"></el-cascader>
                    <el-cascader placeholder="加载中..."
                                 style="width: 400px"
                                 v-if="addLoading"></el-cascader>
                </el-form-item>
                <el-form-item label="简介" prop="brief">
                    <el-input type="textarea" v-model="addForm.brief"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="linkMan">
                    <el-input v-model="addForm.linkMan" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="mobile">
                    <el-input v-model="addForm.mobile" auto-complete="off"></el-input>
                </el-form-item>
                <!--<el-form-item label="店长ID" prop="adminId">
                    <el-input v-model="addForm.adminId" auto-complete="off"></el-input>
                </el-form-item>-->
                <el-form-item label="地址" prop="address">
                    <el-input type="address" v-model="address"></el-input>
                    <el-input type="addressInfo" v-model="addressInfo"></el-input>
                </el-form-item>
                <el-form-item label="店铺详情" prop="details">
                    <el-input type="textarea" v-model="addForm.details"></el-input>
                </el-form-item>
                <el-form-item label="营业执照" prop="businessLicenceImage">
                    <el-upload class="avatar-uploader"
                               action="/api/picture"
                               :show-file-list="false"
                               :on-success="handleBLImageSuccess"
                               :before-upload="beforeLogoImageUpload">
                        <img v-if="businessLicenceImage" :src="businessLicenceImage" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="店内场景" prop="images">
                    <el-upload action="/api/picture"
                               list-type="picture-card"
                               :on-preview="handlePictureCardPreview"
                               :on-remove="handleRemove"
                               :on-success="handleImagesSuccess"
                               :before-upload="beforeLogoImageUpload">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item label="地图">
                    <!--腾讯地图-->
                    <tencentmap :position="position" :mapid="mapid" @changePosition="changePosition" @changeAddress="changeAddress" @changeAddressInfo="changeAddressInfo"></tencentmap>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="addSubmit"
                           :loading="addLoading">提交</el-button>
            </div>
        </el-dialog>

        <!--编辑界面-->
        <el-dialog title="编辑"
                   :visible.sync="editFormVisible"
                   v-model="editFormVisible"
                   @close="formclear"
                   :close-on-click-modal="false">
            <el-form :model="editForm"
                     label-width="80px"
                     :rules="editFormRules"
                     ref="editForm">
                <el-form-item label="名称" prop="storeName">
                    <el-input v-model="editForm.storeName" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="编号" prop="number">
                    <el-input v-model="editForm.number" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="门头照" prop="logoImage">
                    <el-upload class="avatar-uploader"
                               action="/api/picture"
                               :show-file-list="false"
                               :on-success="handleLogoImageSuccess"
                               :before-upload="beforeLogoImageUpload">
                        <img v-if="logoImage" :src="logoImage" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="类别" prop="type">
                    <el-radio-group v-model="editForm.type">
                        <el-radio :label="0">超市</el-radio>
                        <el-radio :label="1">服务商</el-radio>
                        <el-radio :label="2">团长</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="服务区域" prop="selectcities" v-if="editForm.type==1">
                    <el-checkbox-group v-model="selectcities">
                        <el-checkbox-button v-for="city in cities" :label="city" :key="city">{{city}}</el-checkbox-button>
                    </el-checkbox-group>
                </el-form-item>

                <el-form-item prop="categoryIdArr" label="分类" width sortable>
                    <el-cascader placeholder="请选择，支持搜索功能"
                                 style="width: 400px"
                                 v-model="editForm.categoryIdArr"
                                 :options="categorys"
                                 filterable
                                 :key="isResouceShow"
                                 :props="{ checkStrictly: true, expandTrigger: 'hover' }"
                                 v-if="!addLoading"></el-cascader>
                    <el-cascader placeholder="加载中..."
                                 style="width: 400px"
                                 v-if="addLoading"></el-cascader>
                </el-form-item>
                <el-form-item label="简介" prop="brief">
                    <el-input type="textarea" v-model="editForm.brief"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="linkMan">
                    <el-input v-model="editForm.linkMan" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="mobile">
                    <el-input v-model="editForm.mobile" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="店长ID" prop="adminId">
                    <el-input v-model="editForm.adminId" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="地址" prop="address">
                    <el-input type="address" v-model="address"></el-input>
                    <el-input type="addressInfo" v-model="addressInfo"></el-input>
                    <!--<el-input v-model="editForm.address" auto-complete="off"></el-input>-->
                </el-form-item>
                <el-form-item label="店铺详情" prop="details">
                    <el-input type="textarea" v-model="editForm.details"></el-input>
                </el-form-item>
                <el-form-item label="营业执照" prop="businessLicenceImage">
                    <el-upload class="avatar-uploader"
                               action="/api/picture"
                               :show-file-list="false"
                               :on-success="handleBLImageSuccess"
                               :before-upload="beforeLogoImageUpload">
                        <img v-if="businessLicenceImage" :src="businessLicenceImage" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="店内场景" prop="images">
                    <el-upload action="/api/picture"
                               :file-list="fileList"
                               list-type="picture-card"
                               :on-preview="handlePictureCardPreview"
                               :on-remove="handleRemove"
                               :on-success="handleImagesSuccess"
                               :before-upload="beforeLogoImageUpload">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item label="地图">
                    <!--腾讯地图-->
                    <tencentmap :position="position" :mapid="mapid" @changePosition="changePosition" @changeAddress="changeAddress" @changeAddressInfo="changeAddressInfo"></tencentmap>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="editFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="editSubmit"
                           :loading="editLoading">提交</el-button>
            </div>
        </el-dialog>

        <!--更新服务-->
        <el-dialog title="更新服务"
                   :visible.sync="serviceFormVisible"
                   v-model="serviceFormVisible"
                   :close-on-click-modal="false">
            <el-tree :data="serviceData"
                     show-checkbox
                     default-expand-all
                     node-key="value"
                     ref="tree"
                     :props="defaultProps"
                     highlight-current>
                <span class="custom-tree-node" slot-scope="{ node }">
                    <span>{{ node.label }}</span>
                    <!--<el-tag size="small" type="success" v-if="node.data.isbtn">按钮</el-tag>
                    <el-tag size="small" type="info" v-if="node.data.ishide">隐藏</el-tag>-->
                </span>
            </el-tree>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="serviceFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="serviceSubmit"
                           :loading="serviceLoading">提交</el-button>
            </div>
        </el-dialog>

        <!--充值界面-->
        <el-dialog title="充值"
                   :visible.sync="rechargeFormVisible"
                   v-model="rechargeFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="rechargeForm"
                     label-width="80px"
                     :rules="rechargeFormRules"
                     ref="rechargeForm">
                <el-form-item label="用户名" prop="nickName">
                    {{ rechargeForm.storeName  }}
                </el-form-item>
                <el-form-item label="充值金额" prop="balance">
                    <el-input v-model="rechargeForm.balance" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="充值说明" prop="message">
                    <el-input v-model="rechargeForm.message" auto-complete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="rechargeFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="rechargeSubmit"
                           :loading="rechargeLoading">提交</el-button>
            </div>
        </el-dialog>

        <!--负责人界面-->
        <el-dialog title="分配负责人"
                   :visible.sync="operaterFormVisible"
                   v-model="operaterFormVisible"
                   :close-on-click-modal="false">
            <el-form :model="operaterForm"
                     label-width="100px"
                     :rules="operaterFormRules"
                     ref="operaterForm">
                <el-form-item label="负责人" prop="data">
                    <el-input v-model="operaterForm.data" auto-complete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="operaterFormVisible = false">取消</el-button>
                <el-button type="primary"
                           @click.native="operaterSubmit"
                           :loading="operaterLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    import {
        getRoleListPage,
        getStoreListPage,
        getStoreCategoryTree,
        addStore,
        getStore,
        editStore,
        putOnStore,
        putOffStore,
        removeStore,
        getStoreGoodsTree,
        getSelectedGoods,
        setSelectedGoods,
        exportStoreList,
        rechargeStore,
        doEditOperater,
    } from "../../api/api";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/Toolbar";
    import tencentmap from "../../components/TencentMap";
    import VDistpicker from 'v-distpicker'
    import QRCode from 'qrcodejs2';
    export default {
        components: { Toolbar, tencentmap, VDistpicker },
        data() {
            return {
                select_options: [{
                    value: '1',
                    label: '上架'
                }, {
                    value: '2',
                    label: '下架'
                }],
                select_type_options: [{
                    value: '1',
                    label: '超市'
                }, {
                    value: '2',
                    label: '服务商'
                }, {
                    value: '3',
                    label: '团长'
                }],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },

                filters: {
                    name: "", //双向绑定搜索内容
                    category: '',
                    state: '',
                    type: '',
                    picker_start: '',
                    picker_end: '',
                    province: '',
                    city: '',
                    area: '',
                    mobile: "",
                    street:'',
                    manager:'',
                },
                placeholders: {
                    province: '请选择省份',
                    city: '请选择城市',
                    area: '请选择区县',
                },
                mapid: "",
                position: {
                    longitude: 120.448061,//经度
                    latitude: 36.135088,//纬度
                },
                srcList1: [],//营业执照
                srcList2: [],//服务协议
                address: "",//省市区
                addressInfo: "",//详细地址
                selectcities: [],
                cities: [],
                datalist: [],
                roles: [],
                categorys: [],
                total: 0,
                buttonList: [],
                currentRow: null,
                page: 1,
                limit: 20,

                listLoading: false,
                sels: [], //列表选中列
                addDialogFormVisible: false,

                serviceData: [], //批量导入按钮数据
                serviceFormVisible: false, //批量导入按钮
                serviceLoading: false,
                serviceForm: [],
                defaultProps: {
                    children: "children",
                    label: "label",
                },

                //图片信息
                logoImage: "",
                businessLicenceImage: "",
                /*imageList: [],*/
                fileList: [],
                //fileList: [{ name:"1", url:"/upload/product/fcaac840-afb1-4fcd-9340-092cd1ea38f0.jpg" }, { name:"2", url:"/upload/product/fcaac840-afb1-4fcd-9340-092cd1ea38f0.jpg" }],
                ///图片弹窗
                dialogImageUrl: '',
                dialogVisible: false,

                //新增界面配置信息
                addFormVisible: false, //新增界面是否显示
                addLoading: false,
                addFormRules: {
                    storeName: [{ required: true, message: "请输入店铺名称", trigger: "blur" },],
                    //type: [{ required: true, message: "请选择店铺类型", trigger: "blur" }],
                },
                addForm: {},//新增界面数据

                //编辑界面配置信息
                editFormVisible: false, //编辑界面是否显示
                editLoading: false,
                editFormRules: {
                    storeName: [{ required: true, message: "请输入店铺名称", trigger: "blur" },],
                },
                editForm: {},//编辑界面数据
                isResouceShow: 0,


                ///充值弹窗
                rechargeFormVisible: false, //编辑界面是否显示
                rechargeLoading: false,
                rechargeFormRules: {
                    balance: [{ required: true, message: "请输入充值金额", trigger: "blur" },],
                },
                //充值界面数据
                rechargeForm: {},

                ///业务人员
                operaterFormVisible: false, //编辑界面是否显示
                operaterLoading: false,
                operaterFormRules: {},
                //界面数据
                operaterForm: {},

                //二维码弹窗
                codeFormVisible: false,

                qrcode: "",
            };
        },
        methods: {
            formclear() {
                this.address = "";
                this.addressInfo = "";
                this.logoImage = "";
                this.businessLicenceImage = "";
                this.fileList = [];
                this.dialogImageUrl = '';
                this.position = {};
            },
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            callFunction(item) {
                this[item.func].apply(this, item);
            },
            changePosition(position_new) {
                this.position = position_new;
            },
            
            changeAddress(e) {
                console.log(e);
                this.address = e;
            },
            changeAddressInfo(e) {
                console.log(e);
                this.addressInfo = e;
            },

            handSearch() {
                this.handleCurrentChange(0);
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getStores();
            },
            streetChange(val) {
                this.filters.street = val;
            },
            nameChange(val) {
                this.filters.name = val;
            },
            managerChange(val) {
                this.filters.manager = val;
            },
            mobileChange(val) {
                this.filters.mobile = val;
            },
            numberChange(val) {
                this.filters.number = val;
            },
            categoryChange(val) {
                console.log(val);
                if (val.length > 0) {
                    this.filters.category = val[val.length - 1];
                }
            },
            selectTypeChange(val) {
                this.filters.type = val;
            },
            selectTypeClear() {
                this.filters.type = "";
            },
            selectChange(val) {
                this.filters.state = val;
            },
            selectclear() {
                this.filters.state = "";
            },
            onProvinceSelected(data) {
                this.filters.province = data.value;
            },
            onCitySelected(data) {
                this.filters.city = data.value;
            },
            onAreaSelected(data) {
                this.filters.area = data.value;
            },
            onSelected(data) {
                this.filters.province = data.province.value;
                this.filters.city = data.city.value;
                this.filters.area = data.area.value;
            },
            clodeCode() {
                console.log("clode");
                this.codeFormVisible = false;
            },
            handleCode(row) {
                this.codeFormVisible = true;
                this.qrcode.makeCode("https://yapi.haichuangyibai.com/storeinvite?storeid=" + row.id);
                //new QRCode(this.$refs.qrCodeDiv, {
                //    text: 'https://www.baidu.com?storeid=',
                //    width: 200,
                //    height: 200,
                //    colorDark: "#333333", //二维码颜色
                //    colorLight: "#ffffff", //二维码背景色
                //    correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
                //})
            },
            //获取店铺列表
            getStores() {

                this.logoImage = "";
                this.businessLicenceImage = [];
                this.fileList = "";
                let para = {
                    page: this.page,
                    limit: this.limit,
                    number: this.filters.number,
                    key: this.filters.name,
                    category: this.filters.category,
                    state: this.filters.state,
                    type: this.filters.type,
                    province: this.filters.province,
                    city: this.filters.city,
                    area: this.filters.area,
                    street: this.filters.street,
                    mobile: this.filters.mobile,
                    manager: this.filters.manager,
                };
                this.listLoading = true;
                //NProgress.start();
                getStoreListPage(para).then((res) => {
                    this.total = res.data.count;
                    this.datalist = res.data.data;
                    this.listLoading = false;
                    //NProgress.done();
                });
            },

            //导出表格
            exportExcel() {
                let row = this.currentRow;
                //if (!row) {
                //    this.$message({
                //        message: "请选择要导出的店铺！",
                //        type: "error",
                //    });
                //    return;
                //}
                this.$confirm("确认导出店铺吗?", "提示", {
                    type: "warning",
                }).then(() => {
                    this.listLoading = true;
                    //let para = { id: row.id };
                    let para = {
                        page: this.page,
                        limit: this.limit,
                        number: this.filters.number,
                        key: this.filters.name,
                        category: this.filters.category,
                        state: this.filters.state,
                        type: this.filters.type,
                        province: this.filters.province,
                        city: this.filters.city,
                        area: this.filters.area,
                    };
                    exportStoreList(para).then((res) => {
                        this.listLoading = false;
                        if (res.code == 0) {
                            this.$message({
                                message: "导出成功",
                                type: "success",
                            });
                            console.log(res.data);
                            window.open(res.data, '_blank');
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: "error",
                            });
                        }
                        this.listLoading = false;
                    });
                })
                    .catch(() => { });

            },


            //显示更新服务页面
            handleService() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要更新服务的一行数据！",
                        type: "error",
                    });
                    return;
                }
                let that = this;
                that.serviceForm = [];
                that.serviceData = [];
                that.serviceLoading = true;
                that.serviceFormVisible = true;
                /* let para = { id: row.id };*/
                getStoreGoodsTree({ id: row.id }).then((res) => {
                    ++that.isResouceShow;
                    if (res.code == 0) {
                        this.serviceData = res.data.children;
                    }
                    that.serviceLoading = false;

                    //获取已选服务
                    getSelectedGoods({ sid: row.id }).then((res) => {
                        that.$refs.tree.setCheckedKeys([]);
                        if (res.code == 0 && res.data.length > 0) {
                            res.data.forEach(function (item) {
                                let node = that.$refs.tree.getNode(item);
                                if (node.isLeaf) {
                                    that.$refs.tree.setChecked(node, true);
                                }
                            });
                        }
                    });
                });
            },

            //显示充值界面
            handleRecharge() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要绑定的店铺！",
                        type: "error",
                    });
                    return;
                }
                this.rechargeFormVisible = true;
                this.rechargeForm = Object.assign({}, row);
            },


            //显示业务员界面
            handleOperater() {
                let row = this.currentRow;
                if (!row) {
                    if (this.sels.length == 0) {
                        this.$message({
                            message: "请选择店铺！",
                            type: "error",
                        });
                        return;
                    }
                }
                this.operaterFormVisible = true;
                this.operaterForm = Object.assign({}, row);
            },
            //************按钮事件************
            //删除
            handleDel() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要删除的店铺！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认删除该店铺吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        //NProgress.start();
                        let para = { id: row.id };
                        removeStore(para).then((res) => {
                            this.listLoading = false;
                            //NProgress.done();
                            if (res.code == 0) {
                                this.$message({
                                    message: "删除成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getStores();
                        });
                    })
                    .catch(() => { });
            },


            //上架
            handlePutOn() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要上架的店铺！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认上架该店铺吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id };
                        putOnStore(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "上架成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getStores();
                        });
                    })
                    .catch(() => { });
            },

            //上架
            handlePutOff() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要下架的店铺！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确认下架该店铺吗?", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: row.id };
                        putOffStore(para).then((res) => {
                            this.listLoading = false;
                            if (res.code == 0) {
                                this.$message({
                                    message: "下架成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                });
                            }
                            this.getStores();
                        });
                    })
                    .catch(() => { });
            },

            //显示新增界面
            handleAdd() {
                let that = this;
                that.mapid = "container_create";
                that.addFormVisible = true;
                that.addLoading = false;

            },

            //显示编辑界面
            handleEdit() {
                let that = this;
                that.fileList = [];
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的店铺！",
                        type: "error",
                    });
                    return;
                }
                getStore({ id: row.id }).then((res) => {
                    if (res.code == 0) {
                        if (-90 < res.data.latitude && res.data.latitude < 90) {
                            that.position = {
                                longitude: res.data.longitude,//经度
                                latitude: res.data.latitude,//纬度
                            };
                        }
                        that.address = res.data.address;
                        that.addressInfo = res.data.addressInfo;
                        that.logoImage = res.data.logoImage;
                        that.businessLicenceImage = res.data.businessLicenceImage;
                        /* that.imageList = res.data.imageList;*/
                        that.editForm = res.data;
                        that.mapid = "container_edit";
                        that.editFormVisible = true;
                        if (res.otherData != null) {
                            that.cities = res.otherData.cities;
                            that.selectcities = res.otherData.selectcities;
                        };
                        that.editFormVisible = true;
                        for (var i = 0; i < res.data.imageList.length; i++) {
                            var ii = { name: res.data.imageList[i], url: res.data.imageList[i] };
                            that.fileList.push(ii);
                        }
                    }
                });
                this.editLoading = false;
            },

            //充值
            rechargeSubmit: function () {
                this.$refs.rechargeForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认给 【" + this.rechargeForm.storeName + "】 充值 【" + this.rechargeForm.balance + "】 吗？", "提示", {}).then(() => {
                            this.editLoading = true;
                            console.log(this.rechargeForm);
                            let para = Object.assign({}, this.rechargeForm);
                            rechargeStore(para).then((res) => {
                                if (res.code == 0) {
                                    this.rechargeLoading = false;
                                    //NProgress.done();
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["rechargeForm"].resetFields();
                                    this.rechargeForm = false;
                                    this.getStores();
                                    this.rechargeFormVisible = false;
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                            });
                        });
                    }
                });
            },
            //分配负责人
            operaterSubmit: function () {
                this.$refs.operaterForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认将选中店铺负责人修改为【" + this.operaterForm.data + "】 吗？", "提示", {}).then(() => {
                            this.operaterLoading = true;
                            let ids = [];
                            if (this.sels.length > 0) {
                                for (var i = 0; i < this.sels.length; i++) {
                                    ids.push(this.sels[i].id);
                                }
                            }
                            if (ids.length == 0) {
                                ids.push(this.currentRow.id);
                            }
                            if (ids.length == 0) {
                                this.$message({
                                    message: "请选择店铺！",
                                    type: "error",
                                });
                                return;
                            }
                            doEditOperater({ id: ids, data: this.operaterForm.data }).then((res) => {
                                if (res.code == 0) {
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["operaterForm"].resetFields();
                                    this.getStores();
                                    this.operaterFormVisible = false;
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                                this.operaterLoading = false;
                            });
                        });
                    }
                });
            },
            handleChange(value) {
                console.log(value);
            },
            handleClick(row, column, cell, event) {
                if (column.property == "businessLicenceImage") {
                    this.srcList1 = [];
                    this.srcList1 = ["http://5b0988e595225.cdn.sohucs.com/images/20180925/d944208e87534dbda25b715dbe941975.jpeg"];
                }
                else if (column.property == "agreementImage") {
                    this.srcList2 = [];
                    this.srcList2.push("https://pic2.zhimg.com/v2-47ce45fbc5d8ac50f7996737ff4c5f39_1440w.jpg?source=172ae18b");
                }
            },

            //************提交方法************

            //新增
            addSubmit: function () {
                this.$refs.addForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.addLoading = true;
                            //NProgress.start();
                            let para = Object.assign({}, this.addForm);
                            if (para.categoryIdArr != undefined) {
                                para.category = para.categoryIdArr.pop();
                            }
                            para.logoImage = this.logoImage;
                            para.businessLicenceImage = this.businessLicenceImage;
                            if (para.businessLicenceImage == "") {
                                this.$message({
                                    message: "请上传营业执照",
                                    type: "error",
                                });
                                this.addLoading = false;
                            }
                            var imageList = [];
                            for (var i = 0; i < this.fileList.length; i++) {
                                imageList.push(this.fileList[i].url);
                            }
                            para.imageList = imageList;
                            para.address = this.address;
                            para.addressInfo = this.addressInfo;
                            para.coordinate = this.position.latitude + "," + this.position.longitude;
                            addStore(para).then((res) => {
                                if (res.code == 0) {
                                    this.addLoading = false;
                                    //NProgress.done();
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["addForm"].resetFields();
                                    this.addFormVisible = false;
                                    this.addForm = {};
                                    this.getStores();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                };
                                this.addLoading = false;
                            });
                        });
                    }
                });
            },
            //编辑
            editSubmit: function () {
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.$confirm("确认提交吗？", "提示", {}).then(() => {
                            this.editLoading = true;
                            let para = Object.assign({}, this.editForm);
                            if (para.categoryIdArr != undefined) {
                                para.category = para.categoryIdArr.pop();
                            }
                            para.logoImage = this.logoImage;
                            para.businessLicenceImage = this.businessLicenceImage;
                            if (para.businessLicenceImage == "") {
                                this.$message({
                                    message: "请上传营业执照",
                                    type: "error",
                                });
                                this.addLoading = false;
                            }
                            var imageList = [];
                            for (var i = 0; i < this.fileList.length; i++) {
                                imageList.push(this.fileList[i].url);
                            }
                            para.imageList = imageList;
                            para.address = this.address;
                            para.addressInfo = this.addressInfo;
                            para.selectcities = this.selectcities;
                            para.coordinate = this.position.latitude + "," + this.position.longitude;

                            editStore(para).then((res) => {
                                if (res.code == 0) {
                                    this.editLoading = false;
                                    //NProgress.done();
                                    this.$message({
                                        message: res.msg,
                                        type: "success",
                                    });
                                    this.$refs["editForm"].resetFields();
                                    this.editFormVisible = false;
                                    this.getStores();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: "error",
                                    });
                                };
                                this.editLoading = false;
                            });
                        });
                    }
                });
            },

            //设置服务
            serviceSubmit: function () {
                let _this = this;
                _this.serviceLoading = true;
                _this.$refs.tree
                    .getCheckedNodes(false, true)
                    .forEach(function (item) {
                        _this.serviceForm.push(item.value);
                    });
                //if (_this.serviceForm.length == 0) {
                //    _this.$message({
                //        message: "请选择授权信息",
                //        type: "error",
                //    });
                //    return;
                //}
                let row = _this.currentRow;
                let para = { id: row.id, data: _this.serviceForm };
                setSelectedGoods(para).then((res) => {
                    _this.serviceLoading = false;
                    if (res.code == 0) {
                        _this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        _this.serviceFormVisible = false;
                    } else {
                        _this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                });
            },

            selsChange: function (sels) {
                this.sels = sels;
            },


            beforeLogoImageUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },

            handleLogoImageSuccess(res, file) {
                //this.logoImage = "https://younong.haichuangyibai.com/upload/compress/0a7f3ae2-d23e-4716-b4f6-95c0b6f60f25.jpg";
                this.logoImage = res.data.src;
            },
            handleImagesSuccess(res, file) {
                console.log(res);
                console.log(file.raw);
                //this.imageList.push({ name: "", url: "https://younong.haichuangyibai.com/upload/compress/0a7f3ae2-d23e-4716-b4f6-95c0b6f60f25.jpg" });
                this.fileList.push({ name: res.data.src, url: res.data.src });
            },
            handleBLImageSuccess(res, file) {
                //this.businessLicenceImage = "https://younong.haichuangyibai.com/upload/compress/0a7f3ae2-d23e-4716-b4f6-95c0b6f60f25.jpg";
                this.businessLicenceImage = res.data.src;
            },
            handleRemove(file, fileList) {
                this.fileList = fileList;
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            //获取店铺分类
            getStoreCategoryList() {
                let that = this;
                getStoreCategoryTree({}).then((res) => {
                    if (res.data.code == 0) {
                        that.categorys = [];
                        that.categorys.push(res.data.data);
                    }
                });
            },
        },
        mounted() {
            this.getStores();
            this.getStoreCategoryList();
            this.qrcode = new QRCode(this.$refs.qrCodeDiv, {
                text: '',
                width: 200,
                height: 200,
                colorDark: "#333333", //二维码颜色
                colorLight: "#ffffff", //二维码背景色
                correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
            }),
                getRoleListPage().then((res) => {
                    this.roles = res.data.data;
                });
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers);
        },
    };
</script>

<style>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }







    #qrCode {
        display: inline-block;
        margin: 0px auto;
        position: relative;
        top: 25px;
        left: 25px;
    }

        #qrCode img {
            width: 200px;
            height: 200px;
            background-color: #fff;
        }
</style>
