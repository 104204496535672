<template>
    <div>
        <!--<el-tag>{{this.mapid}}</el-tag>
        <el-tag>{{this.position}}</el-tag>-->
        <div style="position:relative;" v-if="update">
            <div :id="mapid"></div>
            <div class="panel"><input type="text" id="place" placeholder="输入地点" /></div>
        </div>
    </div>
</template>
<script>
    //let map, marker, point, infoWindow, geolocation, ap, geocoder;
    let map, marker, infoWindow, geocoder;
    export default {
        //inject: ['reload'],
        data() {
            return {
                update: true,
            }
        },
        props: ["position", "mapid"], //接受父组件传值
        methods: {
            //位置信息在地图上展示
            setMapData() {
                var that = this;
                var latitude = this.position.latitude;
                var longitude = this.position.longitude;
                var point = new TMap.LatLng(latitude, longitude);
                console.log(point)
                // 初始化地图
                map = new TMap.Map(that.mapid, {
                    zoom: 17, // 设置地图缩放
                    center: point, // 设置地图中心点坐标，
                });
                marker = new TMap.MultiMarker({
                    map: map,
                    styles: {
                        // 点标记样式
                        marker: new TMap.MarkerStyle({
                            width: 20, // 样式宽
                            height: 30, // 样式高
                            anchor: { x: 10, y: 30 }, // 描点位置
                            src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png', // 标记路径
                        }),
                    },
                    geometries: [
                        // 点标记数据数组
                        {
                            position: point,// 标记位置(经度，纬度，高度)
                        },
                    ],
                });
                //初始化infoWindow
                infoWindow = new TMap.InfoWindow({
                    map: map,
                    position: point,
                    offset: { x: 0, y: -32 } //设置信息窗相对position偏移像素
                });
                geocoder = new TMap.service.Geocoder(); // 新建一个正逆地址解析类
                // 将给定的坐标位置转换为地址
                geocoder.getAddress({ location: point }).then((result) => {
                    var title = "";
                    var address_ = "";
                    if (result.status == 0) {
                        address_ = result.result.address;
                        if (name != "" && name != undefined) {
                            title = name;
                        }
                        else {
                            console.log(result.result.formatted_addresses);
                            if (result.result.formatted_addresses) {
                                title = result.result.formatted_addresses.recommend;
                            }
                        }
                    }
                    infoWindow.setContent('<div class="map_popinfo"><p class="title">' + title + '</p><p class="address">地址：' + address_ + '</p></div>');//设置信息窗内容
                });

                //监听点击事件添加marker
                map.on("click", (evt) => {
                    point = new TMap.LatLng(evt.latLng.lat, evt.latLng.lng);
                    var name = "";
                    if (evt.poi != null) {
                        name = evt.poi.name;
                    };
                    that.position.longitude = point.lng;
                    that.position.latitude = point.lat;
                    marker.setGeometries([]);
                    marker.add({
                        position: point
                    });
                    infoWindow.setPosition(point);//设置信息窗位置
                    infoWindow.open();
                    // 将给定的坐标位置转换为地址
                    geocoder.getAddress({ location: point }).then((result) => {
                        var title = "";
                        var address_ = "";
                        var return_address = "";
                        var return_address_info = "";
                        if (result.status == 0) {
                            address_ = result.result.address;
                            console.log(result.result);
                            var temp_address = result.result.address_component;
                            return_address = temp_address.province + " " + temp_address.city + " " + temp_address.district;
                            if (name != "" && name != undefined) {
                                title = name;
                                return_address_info = name;
                            }
                            else {
                                console.log(result.result);
                                if (result.result.formatted_addresses) {

                                    title = result.result.formatted_addresses.recommend;
                                    return_address_info = temp_address.street + title;
                                }
                            }
                        }
                        infoWindow.setContent('<div class="map_popinfo"><p class="title">' + title + '</p><p class="address">地址：' + address_ + '</p></div>');//设置信息窗内容
                        this.$emit("changeAddress", return_address); //将值传给父组件
                        this.$emit("changeAddressInfo", return_address_info); //将值传给父组件
                    });
                    this.$emit("changePosition", that.position); //将值传给父组件


                });


                var ap = new window.qq.maps.place.Autocomplete(document.getElementById('place'));
                var keyword = "";
                //调用Poi检索类。用于进行本地检索、周边检索等服务。
                var searchService = new window.qq.maps.SearchService({
                    complete: function (results) {
                        if (results.type === "CITY_LIST") {
                            searchService.setLocation(results.detail.cities[0].cityName);
                            searchService.search(keyword);
                            return;
                        }
                        var pois = results.detail.pois;
                        if (pois != undefined) {
                            var searchpoint = pois[0];
                            //修改地图中心点
                            point = new TMap.LatLng(searchpoint.latLng.lat, searchpoint.latLng.lng);
                            map.setCenter(point);
                            marker.setGeometries([]);
                            marker.add({
                                position: point
                            });
                            infoWindow.setPosition(point);//设置信息窗位置
                            var title = "";
                            var address = "";
                            if (searchpoint.name != undefined) {
                                title = searchpoint.name;
                            }
                            if (searchpoint.address != undefined) {
                                address = searchpoint.address;
                            }
                            infoWindow.setContent('<div class="map_popinfo"><p class="title">' + title + '</p><p class="address">地址：' + address + '</p></div>');//设置信息窗内容
                        }
                    }
                });
                //添加监听事件
                window.qq.maps.event.addListener(ap, "confirm", function (res) {
                    keyword = res.value;
                    searchService.search(keyword);
                });
            },
            createMarker(name) {
                var that = this;
                var title = "";
                var address_ = "";
                if (marker == undefined) {
                    marker = new TMap.MultiMarker({
                        map: map,
                        styles: {
                            // 点标记样式
                            marker: new TMap.MarkerStyle({
                                width: 20, // 样式宽
                                height: 30, // 样式高
                                anchor: { x: 10, y: 30 }, // 描点位置
                                src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png', // 标记路径
                            }),
                        },
                        geometries: [
                            // 点标记数据数组
                            {
                                position: point,// 标记位置(经度，纬度，高度)
                            },
                        ],
                    });
                }
                else {
                    marker.setGeometries([]);
                    marker.add({
                        position: point
                    });
                }
                if (infoWindow == undefined) {
                    //初始化infoWindow
                    infoWindow = new TMap.InfoWindow({
                        map: map,
                        position: point,
                        offset: { x: 0, y: -32 } //设置信息窗相对position偏移像素
                    });
                }
                else {
                    infoWindow.setPosition(point);//设置信息窗位置
                    infoWindow.open();
                }
                if (geocoder == undefined) {
                    geocoder = new TMap.service.Geocoder(); // 新建一个正逆地址解析类
                }
                // 将给定的坐标位置转换为地址
                geocoder.getAddress({ location: point }).then((result) => {
                    if (result.status == 0) {
                        address_ = result.result.address;
                        if (name != "" && name != undefined) {
                            title = name;
                        }
                        else {
                            title = result.result.formatted_addresses.recommend;
                        }
                    }
                    infoWindow.setContent('<div class="map_popinfo"><p class="title">' + title + '</p><p class="address">地址：' + address_ + '</p></div>');//设置信息窗内容
                });
                this.$emit("changePosition", that.position); //将值传给父组件
            },
            getMyLocation() {
                var geolocation = new qq.maps.Geolocation("IZNBZ-5DLCU-K2RVE-BRT7H-7EI5Z-DRBNC", "ufarmer");
                geolocation.getLocation(this.showPosition, this.showErr);
                this.showPosition
            },
            showPosition(position) {
                this.position.latitude = position.lat;
                this.position.longitude = position.lng;
                console.log(this.position);
                /*this.setMapData();*/
            },
            ///定位失败再请求定位，测试使用
            showErr() {
                console.log("定位失败，请重试！");
                this.setMapData();
            },

            /**搜索地点 */
            search() {
                var ap = new window.qq.maps.place.Autocomplete(document.getElementById('place'));
                var keyword = "";
                //调用Poi检索类。用于进行本地检索、周边检索等服务。
                var searchService = new window.qq.maps.SearchService({
                    complete: function (results) {
                        if (results.type === "CITY_LIST") {
                            searchService.setLocation(results.detail.cities[0].cityName);
                            searchService.search(keyword);
                            return;
                        }
                        var pois = results.detail.pois;
                        if (pois != undefined) {
                            var searchpoint = pois[0];
                            //修改地图中心点
                            point = new TMap.LatLng(searchpoint.latLng.lat, searchpoint.latLng.lng);
                            map.setCenter(point);
                            marker.setGeometries([]);
                            marker.add({
                                position: point
                            });
                            infoWindow.setPosition(point);//设置信息窗位置
                            var title = "";
                            var address = "";
                            if (searchpoint.name != undefined) {
                                title = searchpoint.name;
                            }
                            if (searchpoint.address != undefined) {
                                address = searchpoint.address;
                            }
                            infoWindow.setContent('<div class="map_popinfo"><p class="title">' + title + '</p><p class="address">地址：' + address + '</p></div>');//设置信息窗内容
                        }
                    }
                });
                //添加监听事件
                window.qq.maps.event.addListener(ap, "confirm", function (res) {
                    keyword = res.value;
                    searchService.search(keyword);
                });
            },
        },
        mounted() {
            console.log("mounted");
            this.setMapData();
        },
        created() {
            console.log("created");
        },
        updated() {
            var point_ = new TMap.LatLng(this.position.latitude, this.position.longitude);
            map.setCenter(point_);//坐标为天安门
            marker.setGeometries([]);
            marker.add({
                position: point_
            });
            infoWindow.setPosition(point_);//设置信息窗位置
            infoWindow.open();
            // 将给定的坐标位置转换为地址
            geocoder.getAddress({ location: point_ }).then((result) => {
                var address = "";
                var title = "";
                if (result.status == 0) {
                    address = result.result.address;
                    title = result.result.formatted_addresses.recommend;
                }
                infoWindow.setContent('<div class="map_popinfo"><p class="title">' + title + '</p><p class="address">地址：' + address + '</p></div>');//设置信息窗内容
            });
        },
        destroyed() {
            console.log("destroyed");
        },
    }
</script>
<style>
    /*腾讯地图样式*/
    .container {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .map_popinfo {
        text-align: left;
    }

        .map_popinfo .title {
            padding: 0;
            margin: 0;
            line-height: 18px;
            font-size: 18px;
            font-weight: 600;
        }

        .map_popinfo .address {
            font-size: 14px;
            padding: 5px 0;
            margin: 0;
            line-height: 24px;
        }

        .map_popinfo .confirm_btn {
            background: #409eff;
            color: #fff;
            padding: 5px 10px;
            border: none;
            border-radius: 5px;
        }

    .panel {
        position: absolute;
        background: #FFF;
        width: 300px;
        padding: 10px;
        z-index: 1001;
        top: 10px;
        left: 10px;
        border-radius: 6px;
    }

        .panel input {
            padding: 10px;
            border: 1px solid #cfcfcf;
            border-radius: 5px;
            width: 92%;
            font-size: 16px;
        }
</style>
